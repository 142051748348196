import { SocialIcon } from "react-social-icons";
import * as React from "react";
import { useStyletron } from "baseui";
import { Layer } from "baseui/layer";
import { StyledLink } from "baseui/link";
import {
  ChevronRight as Icon,
} from "baseui/icon";
import { Unstable_AppNavBar as AppNavBar } from "baseui/app-nav-bar";

function renderItem(item) {
  return item.label;
}

const benyar = () => {
  return (
    <StyledLink
      href="https://www.bestbenyarwatches.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      Benyar Watch
    </StyledLink>
  );
};
const cadisen = () => {
  return (
    <StyledLink
      href="https://www.cadisenwatch.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      Cadisen Watch
    </StyledLink>
  );
};

const curren = () => {
  return (
    <StyledLink
      href="https://www.bestcurrenwatches.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      Curren Watch
    </StyledLink>
  );
};
const megir = () => {
  return (
    <StyledLink
      href="https://www.bestmegirwatches.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      Megir Watch
    </StyledLink>
  );
};
const starking = () => {
  return (
    <StyledLink
      href="https://www.beststarkingwatches.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      Starking Watch
    </StyledLink>
  );
};
const naviforce = () => {
  return (
    <StyledLink
      href="https://www.bestnaviforcewatches.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      Naviforce Watch
    </StyledLink>
  );
};
const paganidesign = () => {
  return (
    <StyledLink
      href="https://www.paganidesignwatch.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      Pagani Design Watch
    </StyledLink>
  );
};
const steeldive = () => {
  return (
    <StyledLink
      href="https://www.steeldivewatch.net"
      target="_blank"
      rel="noopener noreferrer"
    >
      Steeldive Watch
    </StyledLink>
  );
};
const skmei = () => {
  return (
    <StyledLink
      href="https://www.skmeiwatches.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      Skmei Watch
    </StyledLink>
  );
};

const renderInstagram = () => {
  return (
    <SocialIcon
      network="instagram"
      url={config.socialLinks.instagram}
      target="_blank"
      rel="noopener noreferrer"
      bgColor="#CF0077"
      alt="instagram"
      style={{ height: 32, width: 32 }}
    />
  );
};
const renderFacebook = () => {
  return (
    <SocialIcon
      network="facebook"
      url={config.socialLinks.facebook}
      target="_blank"
      rel="noopener noreferrer"
      bgColor="#355592"
      style={{ height: 32, width: 32 }}
    />
  );
};
const renderPinterest = () => {
  return (
    <SocialIcon
      network="pinterest"
      url={config.socialLinks.pinterest}
      target="_blank"
      rel="noopener noreferrer"
      bgColor="#CA2027"
      style={{ height: 32, width: 32 }}
    />
  );
};
const renderTwitter = () => {
  return (
    <SocialIcon
      network="twitter"
      url={config.socialLinks.twitter}
      target="_blank"
      rel="noopener noreferrer"
      bgColor="#55ADEE"
      style={{ height: 32, width: 32 }}
    />
  );
};
const renderTumblr = () => {
  return (
    <SocialIcon
      network="tumblr"
      url={config.socialLinks.tumblr}
      target="_blank"
      rel="noopener noreferrer"
      bgColor="#32506D"
      style={{ height: 32, width: 32 }}
    />
  );
};
const renderEmail = () => {
  return (
    <SocialIcon
      network="mailto"
      url={`mailto:${config.contact.email}`}
      target="_blank"
      rel="noopener noreferrer"
      bgColor="#27ADE4"
      style={{ height: 32, width: 32 }}
    />
  );
};

const MAIN_NAV = [
  {
    icon: Icon,
    item: { label: "Facebook" },
    mapItemToNode: renderFacebook,
    mapItemToString: renderItem,
  },
  {
    icon: Icon,
    item: { label: "instagram" },
    mapItemToNode: renderInstagram,
    mapItemToString: renderItem,
  },
  {
    icon: Icon,
    item: { label: "pinterest" },
    mapItemToNode: renderPinterest,
    mapItemToString: renderItem,
  },
  {
    icon: Icon,
    item: { label: "twitter" },
    mapItemToNode: renderTwitter,
    mapItemToString: renderItem,
  },
  {
    icon: Icon,
    item: { label: "tumblr" },
    mapItemToNode: renderTumblr,
    mapItemToString: renderItem,
  },
  {
    icon: Icon,
    item: { label: "email" },
    mapItemToNode: renderEmail,
    mapItemToString: renderItem,
  },
];
const USER_NAV = [
  {
    icon: Icon,
    item: { label: "Pagani Design Watch" },
    mapItemToNode: paganidesign,
    mapItemToString: renderItem,
  },
  {
    icon: Icon,
    item: { label: "Cadisen Watch" },
    mapItemToNode: cadisen,
    mapItemToString: renderItem,
  },
  {
    icon: Icon,
    item: { label: "Steeldive Watch" },
    mapItemToNode: steeldive,
    mapItemToString: renderItem,
  },
  {
    icon: Icon,
    item: { label: "Starking Watch" },
    mapItemToNode: starking,
    mapItemToString: renderItem,
  },
];

const config = require("data/config");

export default () => {
  const [css] = useStyletron();
  const containerStyles = css({
    boxSizing: "border-box",
    width: "100vw",
    position: "fixed",
    top: "0",
    left: "0",
  });
  return (
    <React.Fragment>
      <Layer>
        <div className={containerStyles}>
          <AppNavBar
            appDisplayName="One More Watches"
            appDisplayNameLink="/"
            mainNav={MAIN_NAV}
            onNavItemSelect={({ item }) => {
              console.log(item);
            }}
            userNav={USER_NAV}
            username="One More Watches"
            userImgUrl="/"
          />
        </div>
      </Layer>
    </React.Fragment>
  );
};
